import { AbstractRepository } from '@/core/repositories/AbstractRepository'
import { CancelablePromise } from '@/core/repositories/CancelablePromise'
import { EntityBuilder } from '@decahedron/entity'
import { Shop } from '@/packages/shop/Shop'
import { AxiosPromise } from 'axios'

export class ShopRepository extends AbstractRepository<Shop> {
  protected baseUrl = process.env.VUE_APP_API_URL
  protected url = 'shops'
  protected entity = Shop

  list (): CancelablePromise<Shop[]> {
    return this.get(this.url)
      .then(response => EntityBuilder.buildMany<Shop>(Shop, response.data.data))
  }

  save (shop: Shop): Promise<Shop> {
    if (shop.id) {
      return this.put(`${this.url}/${shop.id}`, shop)
    }

    return this.post(this.url, shop)
  }

  delete (id: string): AxiosPromise<void> {
    return super.delete(id)
  }
}
