
import { Options, Vue } from 'vue-class-component'
import { container } from 'tsyringe'
import { TrashIcon, CheckCircleIcon, XCircleIcon, PlusCircleIcon } from '@heroicons/vue/outline'
import { Shop } from '@/packages/shop/Shop'
import { ShopRepository } from '@/packages/shop/ShopRepository'

@Options({
  components: {
    TrashIcon,
    CheckCircleIcon,
    XCircleIcon,
    PlusCircleIcon
  }
})
export default class Shops extends Vue {
  shops: Shop[] = null
  shopEditor = new Shop()

  shopRepository = container.resolve(ShopRepository)

  async mounted () {
    this.shops = await this.shopRepository.list()

    /* this.shops = [
      new Shop()
    ] */
  }

  async create () {
    const savedShop = await this.save(this.shopEditor)
  }

  async save (shop: Shop) {
    const savedShop = await this.shopRepository.save(shop)

    if (!shop.id) {
      this.shops.push(savedShop)
    }

    this.reset()

    return savedShop
  }

  reset () {
    this.shopEditor = new Shop()
  }

  edit (shop: Shop) {
    this.shopEditor = shop;

    (this.$refs.shopName as HTMLElement).focus()
  }

  remove (shop: Shop) {
    this.shopRepository.delete(shop.id)
    this.shops = this.shops.filter((filterShop: Shop) => filterShop.id !== shop.id)
  }
}
