<template>
  <div class="shops" v-if="shops">
    <div>
      <div class="shop" v-for="shop in shops" :key="shop.id" @click="edit(shop)">
        <div class="name">
          <div class="abbreviation" :style="`background-color: ${shop.color};`">{{ shop.abbreviation }}</div>
          <span>{{ shop.name }}</span>
        </div>
        <TrashIcon class="icon cursor-pointer" @click="remove(shop)" />
      </div>
    </div>
    <div>
      <div class="shop">
        <div class="name">
          <div class="abbreviation" :style="`background-color: ${shopEditor.color};`">{{ shopEditor.abbreviation }}</div>
          <input ref="shopName" type="text" class="w-full m-0" v-model="shopEditor.name" @keyup.enter="save(shopEditor)" @keyup.esc="reset" />
          <input type="color" id="head" name="head" v-model="shopEditor.color">
        </div>
        <div class="actions">
          <XCircleIcon v-if="shopEditor.name" class="icon cursor-pointer" @click="reset" />
          <PlusCircleIcon v-if="!shopEditor.id" class="icon cursor-pointer" @click="save(shopEditor)" />
          <CheckCircleIcon v-else class="icon cursor-pointer" @click="save(shopEditor)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { container } from 'tsyringe'
import { TrashIcon, CheckCircleIcon, XCircleIcon, PlusCircleIcon } from '@heroicons/vue/outline'
import { Shop } from '@/packages/shop/Shop'
import { ShopRepository } from '@/packages/shop/ShopRepository'

@Options({
  components: {
    TrashIcon,
    CheckCircleIcon,
    XCircleIcon,
    PlusCircleIcon
  }
})
export default class Shops extends Vue {
  shops: Shop[] = null
  shopEditor = new Shop()

  shopRepository = container.resolve(ShopRepository)

  async mounted () {
    this.shops = await this.shopRepository.list()

    /* this.shops = [
      new Shop()
    ] */
  }

  async create () {
    const savedShop = await this.save(this.shopEditor)
  }

  async save (shop: Shop) {
    const savedShop = await this.shopRepository.save(shop)

    if (!shop.id) {
      this.shops.push(savedShop)
    }

    this.reset()

    return savedShop
  }

  reset () {
    this.shopEditor = new Shop()
  }

  edit (shop: Shop) {
    this.shopEditor = shop;

    (this.$refs.shopName as HTMLElement).focus()
  }

  remove (shop: Shop) {
    this.shopRepository.delete(shop.id)
    this.shops = this.shops.filter((filterShop: Shop) => filterShop.id !== shop.id)
  }
}
</script>

<style lang="scss">
.shops {
  @apply bg-gray-200 rounded-t-xl py-3 h-full flex flex-col;

  .shop {
    @apply m-4 p-4 flex justify-between items-center text-lg rounded-xl bg-white shadow;

    .name {
      @apply flex items-center;

      .abbreviation {
        @apply rounded-xl bg-gray-800 flex justify-center items-center p-2 mr-3 text-sm w-6 text-white;
        aspect-ratio: 1 / 1;
      }

      span {
        @apply text-blue-900 font-bold;
      }

      input:focus {
        @apply text-lg text-blue-900 font-bold;
      }
    }

    .icon {
      @apply text-blue-900;

      &:hover {
        @apply text-blue-700 cursor-pointer;
      }
    }

    .actions {
      @apply flex;
    }
  }
}
</style>
